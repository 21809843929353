import "../css/gutenberg.css"
import "moment/locale/de"
import "twin.macro"
import { Link, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { TabContext } from "../contexts/tab-context"
import ContactAnimation from "../components/animations/contact"
import Container from "../components/container"
import EventList from "../components/events/list"
import Factbox from "../components/factbox"
import HomeMiddleAnimation from "../components/animations/home-middle"
import Indent from "../components/indent"
import Instagram from "../components/social/instagram"
import Linkedin from "../components/social/linkedin"
import moment from "moment/moment"
import PersonList from "../components/persons/list"
import React, { lazy, Suspense, useContext } from "react"
import Seo from "../components/seo"
import Spacing from "../components/spacing"
import tw from "twin.macro"
import Twitter from "../components/social/twitter"

const Animation = lazy(() => import("../components/animation"))

const HomeTemplate = ({
  data: { page, events, members, protagonists, seo },
}) => {
  //const blocks = page.blocks?.blocks || []
  const blocks = []

  //const [filteredBlocks] = extendBlocksWithSlugs(blocks)
  events = events.edges.map(({ node }) => node)

  console.log(seo.seo)

  const eventsOnFirstDay = events.filter(
    event =>
      moment(event.startDate).format("DD.MM.YYYY") ===
      moment("2022-12-05").format("DD.MM.YYYY")
  )
  const eventsOnLastDay = events.filter(
    event =>
      moment(event.startDate).format("DD.MM.YYYY") ===
      moment("2022-12-06").format("DD.MM.YYYY")
  )

  protagonists = protagonists.edges.map(({ node }) => node)
  const anchors = []

  const { showFirstDay, setShowFirstDay } = useContext(TabContext)

  return (
    <>
      <Seo title={seo.seo.title} description={seo.seo.excerpt} lang="de" />

      <div>
        <div tw="grid grid-cols-1 md:grid-cols-2">
          <div tw="col-span-1 flex justify-end bg-sand-500">
            <div tw="flex w-full max-w-screen-md pl-4 sm:pl-10 lg:pl-20">
              <div tw="flex max-h-screen w-full flex-col justify-between">
                <div style={{ minHeight: 180 }}></div>
                <div tw="mb-8 w-full">
                  <div tw="mb-2 font-regular text-xl lg:mb-4 lg:text-3xl">
                    2. Gleichstellungstag
                  </div>
                  <div tw="text-5xl font-bold lg:text-7xl">zusammen:</div>
                  <div tw="mb-3 text-5xl font-bold md:mb-7 lg:ml-30 lg:text-7xl xl:ml-40">
                    wirken
                  </div>
                  <div tw="mb-7 max-w-md font-regular text-xl lg:text-3xl">
                    Wandel wird mit Gleichstellung gemacht.
                  </div>
                  <div tw="mb-7 max-w-md font-bold text-base lg:text-3xl">
                    <span tw="bg-lilac-300 p-2">5. November 2024</span><br />
                    <span tw="bg-lilac-300 p-2">bcc Berlin Congress Center</span>
                  </div>
                </div>
                <div tw="mb-8 w-auto">
                  <div tw="text-xl lg:text-3xl font-bold">#EsIstZeit<span tw="ml-5">#ZusammenWirken</span></div>
                  <div tw="flex">
                    <Linkedin />
                    <Instagram />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div tw="col-span-1">
            <div tw="aspect-ratio-3/5 md:aspect-ratio-2/3 lg:aspect-ratio-2/3 xl:aspect-ratio-4/5">
              <Suspense>
                <Animation />
              </Suspense>
            </div>
          </div>
        </div>
        <section id="savethedate" tw="">
          <Spacing space="extralarge" padding>
            <Container>
              {/*<div tw="mb-12 mt-8 flex flex-row flex-wrap md:mb-16">
                {anchors.map((item, index) => (
                  <a
                    href={`#${item.id}`}
                    tw="mr-5 mb-5 inline-block bg-lilac-500 px-7 py-2 font-bold tracking-widest text-anthracite-500 no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                    key={`anchor-${index}`}
                  >
                    {item.label}
                  </a>
                ))}
              </div>*/}
              <h1 tw="mb-11 text-3xl font-bold md:text-4xl">
              Unter dem Motto „zusammen:wirken – Wandel wird mit Gleichstellung gemacht.” steht beim 2. Gleichstellungstag alles im Zeichen gesellschaftlicher Veränderungsprozesse aus gleichstellungspolitischer Perspektive.
              </h1>
              <Indent>
                <p tw="pb-8 text-xl">Wir sind uns sicher: Gleichstellung ist die Basis für gelingende, nachhaltige Transformationen. Gleichzeitig eröffnen Umbrüche als Prozesse tiefgreifender Veränderungen neue Chancen für eine geschlechtergerechte Zukunft.</p>
                <p tw="pb-8 text-xl">Unterschiedliche Aspekte der sozial-ökologischen Transformation und weiterer zentraler Veränderungen sollen beim 2. Gleichstellungstag thematisch in den Blick genommen werden: Der Klimawandel fordert Wirtschaft und Gesellschaft zu grundlegenden Änderungen in kurzer Frist heraus. Die Digitalisierung wird vorangetrieben und verändert Arbeits- und Lebenswelten. In Europa macht sich der demografische Wandel bemerkbar, in verschiedenen Regionen Deutschlands der Strukturwandel. Diese Umbrüche müssen gestaltet werden: sozial und ökologisch, gleichstellungsorientiert und demokratisch. Migrationsbewegungen, Flucht und Asyl, geschlechtergerechte Stadt- und Raumplanung oder Geschlechtervielfalt sind ebenso wichtige Themen wie Parität, neue Arbeitszeit-/Lebenszeitmodelle, ökonomische Gleichstellung oder der Umgang mit erstarkendem Antifeminismus.</p>
                <p tw="pb-8 text-xl">Wie das Potenzial des Wandels für gleichstellungspolitische Ziele genutzt werden kann, u. a. darüber möchten wir beim 2. Gleichstellungstag in Berlin mit Vertreter*innen von Verwaltung, Verbänden und Institutionen, aus Wissenschaft und Politik sowie Aktiven der Gleichstellungsszene und Interessierten diskutieren und wirkungsvolle Lösungen erarbeiten.</p>
                <p tw="pb-8 text-xl">Mit rund 30 Workshops, Foren und Diskussionsrunden hält der Fachkongress vielfältige Formate bereit, die überwiegend von Verbänden, Vereinen, Initiativen und Organisationen gestaltet werden. Auf der parallel stattfindenden Fachmesse präsentieren rund 40 Aussteller*innen ihre Arbeit und Projekte und bieten die Möglichkeit, in den Austausch zu kommen. Abgerundet wird die Veranstaltung von einem Rahmenprogramm. Für alle, die nicht in Berlin dabei sein können, wird das Programm auf der Hauptbühne live im Internet gestreamt.</p>
                <p tw="pb-8 text-xl">Das Bild der Veränderung und der Wechselwirkung findet sich auch im Gestaltungskonzept des 2. Gleichstellungstags wieder: Wir verwenden das Kaleidoskop als Symbol für unsere Gesellschaft in Bewegung – es steht für ständigen Wandel. Gleichzeitig visualisiert das Kaleidoskop Komplexität und Vielschichtigkeit. Bei jeder Bewegung setzen sich die Einzelteile im Inneren anders zusammen und bilden permanent neue Gefüge. Im Kontext der Gleichstellung hat das Kaleidoskop eine weitere ebenso wichtige Bedeutung für uns: Die Muster entstehen durch eine Brechung an Spiegelprismen. Unser Spiegel, in dem wir Wandlungsprozesse betrachten wollen, ist das Thema Gleichstellung.</p>
                {/**<Link
                  tw="mt-12 inline-block bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                  title="ANMELDUNG"
                  to="/anmeldung"
                >
                  ANMELDUNG
                </Link>**/}
              </Indent>
              <div tw="mt-20 ">
                <Factbox />
              </div>
            </Container>
          </Spacing>
        </section>

        <section
          id="programm"
          tw="z-0 w-full bg-lilac-300/50 rounded p-6 mt-4"
        >
          <Spacing padding space="medium">
            <Container>
              <div className="mask-events">
                <div tw="mb-10 lg:mb-18">
                  <h1 tw="text-3xl font-bold lg:text-5xl">Programmübersicht</h1>
                </div>
                <EventList events={events} />
              </div>
              <Link
                tw="inline-block text-center z-50 bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                title="ANMELDUNG"
                to="/programmuebersicht"
              >
                GESAMTES PROGRAMM
              </Link>
            </Container>
          </Spacing>
        </section>

        <section id="highlights"></section>

        <section tw="" id="aktive">
          <Spacing space="extralarge" padding>
            <Container>
              <div tw="mb-10 flex items-end  justify-between lg:mb-18">
                <h1 tw="text-3xl font-bold lg:text-5xl">
                  Aktive auf der Bühne
                </h1>
              </div>
              <Indent>
                <PersonList protagonists={protagonists} />

                <p tw="pt-4">
                  <Link
                    tw="bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                    title="MEHR"
                    to="/persons"
                  >
                    MEHR
                  </Link>
                </p>

              </Indent>
            </Container>
          </Spacing>
        </section>


        <div tw="grid grid-cols-1">
          <div tw="col-span-1">
            <div tw="">
              <Suspense>
                <HomeMiddleAnimation />
              </Suspense>
            </div>
          </div>
        </div>

        <section id="savethedate" tw="">
          <Spacing space="extralarge" padding>
            <Container>
              <h1 tw="mb-11 text-3xl font-bold md:text-4xl">
              bcc Berlin Congress Center
              </h1>
              <Indent>
                <p tw="pb-8 text-xl">Unser diesjähriger Veranstaltungsort kann auf eine bewegte Vergangenheit zurückblicken: Einst stand am Alexanderplatz das „Lehrervereinshaus", das 1945 den Fliegerbomben des Zweiten Weltkriegs zum Opfer fiel. An seiner Stelle wurde von 1961 bis 1964 das „Haus des Lehrers" errichtet, das mit seinem separaten Kuppelbau beeindruckt. In den 80er- und 90er-Jahren wurde dieser Gebäudeteil, der durch klare Formen und viele interessante architektonische Details besticht, meist als Kongresshalle bezeichnet. Heute bildet er das Herzstück des bcc. Neben dem Kuppelsaal des denkmalgeschützten Hauses stehen im bcc 25 weitere Veranstaltungsräume zur Verfügung. Insgesamt bietet sich Platz für bis zu 1.500 Personen auf über 5.000 Quadratmetern und drei Ebenen.</p>
                <div class="container mx-auto p-4">
                  <div tw="flex flex-wrap -mx-4">
                    <div tw="w-full lg:w-3/5 px-4 mb-4 lg:mb-0">
                      <StaticImage
                        src="../../content/assets/foto-bcc_www.jpg"
                        alt="Unsere Gesellschaft braucht Gleichstellung. Es ist Zeit"
                        tw="h-full w-full object-cover"
                        load="lazy"
                      />
                    </div>
                    <div tw="w-full lg:w-2/5 px-4">
                      <StaticImage
                        src="../../content/assets/logo-bcc.png"
                        alt="Unsere Gesellschaft braucht Gleichstellung. Es ist Zeit"
                        tw="h-full w-full object-cover"
                        load="lazy"
                      />
                    </div>
                  </div>
                </div>
                <p tw="pt-8 text-sm font-bold">Veranstaltungsort</p>
                <p tw="pt-2 text-sm">
                  bcc Berlin Congress Center GmbH<br />
                  Alexanderstr. 11<br />
                  10178 Berlin
                </p>

                <p tw="pt-12">
                  <Link
                    tw="bg-lilac-500 px-7 py-3 text-xl font-bold uppercase tracking-widest text-black no-underline transition-colors duration-300 hover:bg-lilac-300 focus:bg-lilac-300"
                    title="ANFAHRT"
                    target="_blank"
                    to="https://www.openstreetmap.org/directions?from=&to=52.52066%2C13.41642"
                  >
                    ANFAHRT
                  </Link>
                </p>

              </Indent>
            </Container>
          </Spacing>
        </section>

        <section id="kontakt">
          <div tw="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            <div tw="">
              <ContactAnimation />
            </div>
            <div tw="flex bg-sand-500 md:items-center md:justify-center">
              <div tw="max-w-lg py-10 px-4 sm:px-10 lg:px-20 xl:px-10">
                <h3 tw="mb-12 text-3xl font-bold lg:text-4xl">Kontakt</h3>
                <p tw=" mb-6">
                  <b tw="text-xl font-bold" style={{ fontWeight: "normal" }}>
                    Bundesstiftung Gleichstellung
                  </b>
                  <br /><br />
                  <b tw="text-xl font-bold" style={{ fontWeight: "normal" }}>
                    Team Presse, Kommunikation und Veranstaltungen
                  </b>
                </p>
                <p tw="">
                  +49 30 994 0570-26
                  <br />
                  <a
                    href="mailto:gleichstellungstag@bundesstiftung-gleichstellung.de"
                    tw="text-link"
                  >
                    E-Mail
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default HomeTemplate

export const pageQuery = graphql`
  query HomeById {
    site {
      siteMetadata {
        siteUrl
      }
    }
    wp {
      seo {
        schema {
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
    seo: wpPage(id: { eq: "cG9zdDoxNTMz" }) {
      ...SeoFragment
    }
    page: wpPage(id: { eq: "cG9zdDoxNTMz" }) {
      id
      nodeType
      title
      uri
      content
      date(formatString: "MMMM DD, YYYY")
    ##  ...BlocksFragment
    }
    protagonists: allWpProtagonist(
      sort: { fields: member___positionOnHome }
      filter: {member: {featured: {eq: true}}}
    )
    {
      edges {
        node {
          id
          #title
          content
          slug
          uri
          member {
            jobtitle
            title
            firstname
            lastname
          }
          featuredImage {
            node {
              altText
              imageCopyright {
                photographer
                rights
              }
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 60
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    breakpoints: [320, 480, 640, 756, 920]
                  )
                }
              }
            }
          }
        }
      }
    }

    events: allWpEvent(
      sort: { fields: startDate, order: ASC }
      limit: 2
    ) {
      edges {
        node {
          allDay
          content
          date
          duration
          endDate
          excerpt
          featured
          id
          slug
          startDate
          status
          title
          uri
          eventsCategories {
            nodes {
              name
            }
          }
          organizers {
            nodes {
              title
            }
          }
          venue {
            title
          }
          protagonists {
            moderation {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 60
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          breakpoints: [320, 480, 640, 756, 920]
                        )
                      }
                    }
                  }
                }
              }
            }
            together {
              ... on WpProtagonist {
                id
                #title
                member {
                  category
                  jobtitle
                  title
                  firstname
                  lastname
                }
                featuredImage {
                  node {
                    id
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 60
                          placeholder: BLURRED
                          layout: FULL_WIDTH
                          breakpoints: [320, 480, 640, 756, 920]
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`