import React from "react"
import "twin.macro"
import CountUp from "react-countup"
import { useInView } from "react-intersection-observer"
import HeartAnimation from "./animations/heart"
import BubbleAnimation from "./animations/bubble"
import Balance from "./animations/balance"
import Star from "./animations/star"
import LampIcon from "../images/svgs/lamp.svg"
import LeavesIcon from "../images/svgs/leaves.svg"
import PointIcon from "../images/svgs/point.svg"
import SpeakerAnimation from "../components/animations/speaker"
import { Link } from "gatsby"
import Icon from "./icon"
import ArrowRightIconSm from "../images/svgs/arrow-right.svg"
import TeaserCirclesIcon from "../images/svgs/teaser-circles.svg"
import useReducedMotion from "../hooks/use-reduced-motion"

const Factbox = props => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const reducedMotion = useReducedMotion(false)
  return (
    <>

      <div tw="grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-4 sm:grid-rows-1 md:grid-rows-1 xl:grid-rows-2 gap-4">

          {/** 0 Speaker*innen & */}

          <div tw="flex flex-col bg-sand-500 gap-4 p-10 xl:col-span-2 xl:row-span-2 md:col-span-2 md:row-span-1 sm:col-span-4">
            <div tw="left-0 top-0 flex-grow relative h-59">
              <SpeakerAnimation tw="absolute h-40 w-40 left-0 top-0 z-20 pl-0" />
            </div>
            <div tw="pb-1">
              <h2 tw="mb-3 text-xl font-bold lg:text-[2.75rem] leading-[3.25rem]">Mitwirkende & Speaker*innen</h2>
              <div tw="mb-2 text-xl font-normal">
              Inspirierende Persönlichkeiten und spannende Speaker*innen, die neue Impulse setzen und Perspektiven eröffnen.
              </div>
            </div>

            <div tw="">
              <Link to="/persons" tw="" className="group" id="Persons">
                <Icon svg={ArrowRightIconSm} tw="h-6 w-6 flex-shrink-0 hover:text-lilac-500" />
              </Link>
            </div>
          </div>

          {/** 4 Bundesstiftung Gleichstellung */}

          <div tw="flex flex-col bg-sand-500 p-6">
            <div>
              <h2 tw="mb-3 text-xl font-bold lg:text-3xl">Fachkongress</h2>
            </div>
            <div tw="mb-6 flex-grow text-xl font-normal">
              <p>Zahlreiche Programmangebote laden zu einer inhaltlichen Beschäftigung mit gesellschaftlichen Veränderungen und Gleichstellung ein.</p>
            </div>
            <div tw="pb-2">
              <Link to="/programmuebersicht" tw="" className="group" id="Fachkongress">
                <Icon svg={ArrowRightIconSm} tw="h-6 w-6 flex-shrink-0 hover:text-lilac-500" />
              </Link>
            </div>
          </div>

          {/** 1 Fachmesse */}

          <div tw="flex flex-col bg-sand-500 p-6">
            <div>
              <h2 tw="mb-3 text-xl font-bold lg:text-3xl">Fachmesse</h2>
            </div>
            <div tw="mb-6 flex-grow text-xl font-normal">
              <p>Rund 40 beteiligte Vereine, Bündnisse und Einrichtungen, die informieren und zum Austausch einladen.</p>
            </div>
            <div tw="pb-1">
              <Link to="/fachmesse" tw="" className="group" id="Fachmesse">
                <Icon svg={ArrowRightIconSm} tw="h-6 w-6 flex-shrink-0 hover:text-lilac-500" />
              </Link>
            </div>
          </div>

          {/** 3 FAQ */}

          <div tw="flex flex-col bg-sand-500 p-6">
            <div>
              <h2 tw="mb-3 text-xl font-bold lg:text-3xl">Fragen & Antworten</h2>
            </div>
            <div tw="mb-6 flex-grow text-xl font-normal">
              <p>Noch Fragen? In unseren FAQs finden Sie viele Antworten und weiterführende Informationen.</p>
            </div>
            <div tw="pb-1">
              <Link to="/haeufig-gestellte-fragen" tw="" className="group" id="Fragen & Antworten">
                <Icon svg={ArrowRightIconSm} tw="h-6 w-6 flex-shrink-0 hover:text-lilac-500" />
              </Link>
            </div>
          </div>

          {/** 2 Anmeldung */}

          <div tw="flex flex-col bg-sand-500 p-6">
            <div>
              <h2 tw="mb-3 text-xl font-bold lg:text-3xl">Anmeldung</h2>
            </div>
            <div tw="mb-6 flex-grow text-xl font-normal">
              <p>Der Anmeldezeitraum ist leider vorbei. Die Programmpunkte auf der Hauptbühne werden ganztägig über einen Livestream auf unserer Website zu verfolgen sein.</p>
            </div>
            <div tw="pb-1">
              {/**<Link to="/anmeldung" tw="" className="group" id="Anmeldung">
                <Icon svg={ArrowRightIconSm} tw="h-6 w-6 flex-shrink-0 hover:text-lilac-500" />
              </Link>**/}
            </div>
          </div>

      </div>
    </>
  )
}

export default Factbox
